<template>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <h2>Links</h2>
      </b-col>
      <b-col cols="*" class="mr-3">
        <b-button variant="primary" :to="{ name: 'createLink' }"
          >Create Link
        </b-button>
      </b-col>
    </b-row>
    <hr class="mt-1" />

    <b-form @submit.prevent="filterLinks">
      <b-input-group class="mb-3">
        <b-form-input
          id="nameInput"
          v-model="namePredicate"
          placeholder="Search for an link"
        ></b-form-input>

        <b-input-group-append>
          <b-button type="submit" variant="primary" :disabled="searching"
            >Search<b-spinner small v-if="searching"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>

    <div>
      <b-table
        id="links-table"
        ref="table"
        :items="provider"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        empty-text="No links found."
        show-empty
      >
        <template v-slot:cell(name)="row">
          {{ row.item.link_title }}
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(url)="row">
          {{ row.item.link }}
        </template>

        <template v-slot:cell(actions)="row">
          <LinkActionButton :link="row.item" @changed="$refs.table.refresh()" />
        </template>
      </b-table>

      <b-row align-h="between" class="mt-3">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </b-col>
        <b-col cols="*" class="mr-3"> {{ rows }} links </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import LinkActionButton from "./LinkActionButton.vue";

export default {
  components: {
    LinkActionButton,
  },
  data() {
    return {
      namePredicate: "",
      searching: false,
      rows: 0,
      currentPage: 1,
      perPage: 15,
      fields: [
        { key: "name", label: "Link Name", sortable: true },
        {
          key: "url",
          label: "Link URL",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  methods: {
    async provider(ctx) {
      try {
        if (this.namePredicate == "") {
          let response = await this.$http.get(
            this.$store.state.apiURL + "/links",
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.JWT,
              },
              params: {
                query: this.namePredicate,
                sort: ctx.sortBy.length > 0 ? ctx.sortBy : null,
                direction: ctx.sortDesc ? "desc" : "asc",
                per: ctx.perPage,
                page: ctx.currentPage,
              },
            }
          );
          
          this.rows = response.data.data.length;

          return response.data.data;
        }
        else{
          let response = await this.$http.get(
            this.$store.state.apiURL + "/links",
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.JWT,
              },
              params: {
                title: this.namePredicate,
                sort: ctx.sortBy.length > 0 ? ctx.sortBy : null,
                direction: ctx.sortDesc ? "desc" : "asc",
                per: ctx.perPage,
                page: ctx.currentPage,
              },
            }
          );
          
          this.rows = response.data.data.length;

          return response.data.data;
        }
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
    filterLinks() {
      this.$refs.table.refresh();
    },
  },
  created() {
    if (!this.$store.state.admin) {
      this.fields.pop();
    }
  },
};
</script>
