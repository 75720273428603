<template>
  <b-dropdown no-caret size="sm" variant="primary" :disabled="deleting">
    <template v-slot:button-content>
      <b-icon icon="three-dots-vertical" /> <b-spinner small v-if="deleting" />
    </template>

    <b-dropdown-item
      v-if="true"
      @click="setDeleteLinkID"
      :disabled="deleting"
      ><b-icon icon="trash-fill" scale="0.75" />Delete</b-dropdown-item
    >

    <b-dropdown-item
      v-if="true"
      @click="editLink"
      ><b-icon icon="trash-fill" scale="0.75" />Edit</b-dropdown-item
    >

    <b-modal ref="modalDelete" id="modalDelete" @ok="deleteLink" ok-variant="danger" ok-title="Delete" hide-header>
        <p class="my-4">Are you sure?</p>
    </b-modal>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
      deleteLinkID: ""
    };
  },
  methods: {
    setDeleteLinkID () {
      this.deleteLinkID = this.link.id;

      this.$refs['modalDelete'].show();
    },
    editLink() {
        this.$router.push({ name: "editLink", params: { id: this.link.id } });
    },
    async deleteLink() {
      this.deleting = true;

      try {
        await this.$http.delete(
          this.$store.state.apiURL + "/links/" + this.deleteLinkID,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.$emit("changed");
      } catch (error) {
        this.handleError(error);
      }

      this.deleting = false;
    },
  },
};
</script>
<style lang="scss">
@import "../../scss/theme.scss"; 
</style>
